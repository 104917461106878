import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Menu from "../components/menu"
import MenuSection from "../components/menuSection"

const PartySupplies = () => (
  <Layout>
    <Seo title="Party Supplies" />
    <Banner>
      <StaticImage
        src="../images/party_supplies_banner.jpg"
        alt="Banner image showing Hawkeye decorations, colorful decorations, and patriotic decorations"
        placeholder="blurred"
        layout="fullWidth"
      />
    </Banner>
    <Menu title="Party Supplies">
      <MenuSection>
        <ul>
          <li>High Quality Paper Products</li>
          <li>Piñatas</li>
          <li>Unique &amp; Personalized Gifts</li>
          <li>Sports Teams</li>
        </ul>
      </MenuSection>
      <MenuSection title="Specialty Birthday">
        <ul>
          <li>Kids</li>
          <li>
            1<sup>st</sup> Birthday
          </li>
          <li>Milestone Birthdays</li>
        </ul>
      </MenuSection>
      <MenuSection title="Occasions">
        <ul>
          <li>Baby &amp; Bridal Showers</li>
          <li>Bachelorette</li>
          <li>Graduation</li>
          <li>Gender Reveal</li>
          <li>Anniversary</li>
          <li>Retirement</li>
          <li>Holiday &amp; Seasonal</li>
        </ul>
      </MenuSection>
    </Menu>
  </Layout>
)

export default PartySupplies
